import React, { useEffect } from 'react';
import useAssessment from '../../../../hooks/assessment';
import Button from '../../../Button';
import LoadingSpinner from '../../../../utils/LoadingSpinner';
import { ASSESSMENT_STEPS, PARTICIPANT_VALIDATION_TYPE } from '../../Constants';

const SelectInvitation = () => {
  const {
    nextStep,
    invitations,
    setStep,
    participationType,
    failureInstructions
  } = useAssessment();

  useEffect(() => {
    if (invitations?.length === 1) {
      nextStep(invitations[0]);
    }
  }, [invitations]);

  if (invitations?.length === 1) {
    return null;
  }

  if (!invitations) {
    return (
      <div className="flex flex-col justify-center p-8 mt-8 text-md text-center w-full">
        <LoadingSpinner showLogo={false} />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center p-8 mt-8 text-md text-center w-full">
      {invitations?.length === 0 ? (
        <div className="flex justify-center flex-col">
          <span>You have no active invitations.</span>
          <div className="mt-6 mx-auto max-w-2xl mb-4 border-l-4 border-yellow-400 bg-yellow-50 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-yellow-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true">
                  <path
                    fill-rule="evenodd"
                    d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-lg text-yellow-700">
                  Did you enter your employee code correctly? <br />
                  {failureInstructions ? failureInstructions : ''}
                  <br />
                  If you have already completed the questionnaire, you cannot
                  re-take it.
                </p>
              </div>
            </div>
          </div>
          <div className="mx-aut">
            <Button
              onClick={() =>
                setStep(
                  participationType ===
                    PARTICIPANT_VALIDATION_TYPE.EMPLOYEE_CODE_AND_EMAIL
                    ? ASSESSMENT_STEPS.EMPLOYEE_CODE_AND_EMAIL
                    : ASSESSMENT_STEPS.EMPLOYEE_CODE_AND_LAST_NAME
                )
              }
              text="TRY AGAIN"
            />
          </div>
        </div>
      ) : (
        <div>
          <span>Please choose your organisation</span>
          <div className="flex w-full justify-center">
            {invitations?.map((invitation, index) => (
              <Button
                key={index}
                onClick={() => nextStep(invitation)}
                text={invitation.organisationName}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectInvitation;
