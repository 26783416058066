import React from 'react';
import Insights from '../../Insights';
import useAssessment from '../../../../hooks/assessment';

/*
    insights that are asked before the actual ratings 
  */
const PreInsights = () => {
  const { nextStep } = useAssessment();

  const onNoActionRequired = async () => {
    await nextStep();
  };

  return <Insights onNoActionRequired={onNoActionRequired} />;
};

export default PreInsights;
