import clsx from 'clsx';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';

const Textarea = props => {
  const { dataPoint, onChange, minLabelWidth, minInputWidth } = props;

  const labelStyle = {};
  const inputStyle = {};
  if (dataPoint.inlineLabel) {
    labelStyle.minWidth = minLabelWidth;
    inputStyle.minWidth = minInputWidth;
  } else {
    inputStyle.minWidth = minInputWidth * 2.5;
  }

  if (isMobileOnly) {
    inputStyle.minWidth = '100%';
  }

  return (
    <div
      className={clsx(
        'flex items-center',
        dataPoint?.inlineLabel ? 'flex-row' : 'flex-col'
      )}>
      <label
        htmlFor="email"
        style={labelStyle}
        className="block text-md text-left text-gray-900 mr-4">
        {dataPoint.label}
      </label>
      <textarea
        style={inputStyle}
        rows={4}
        name={dataPoint.name}
        id={dataPoint.id}
        className="mt-2 text-base block flex flex-grow rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
        defaultValue={dataPoint.defaultValue}
        placeholder={dataPoint.placeholder}
        onBlur={e => onChange(dataPoint, e.target.value)}
      />
    </div>
  );
};

export default Textarea;
