import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  ASSESSMENT_STEPS,
  PARTICIPANT_VALIDATION_TYPE
} from '../../components/Assessment/Constants';

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator
} from 'firebase/functions';

import {
  invitationsLoadedAction,
  invitationsSelectedAction,
  submissionEntriesFinalisedAction,
  nextStepAction,
  previousStepAction,
  colleaguesSelectedAction,
  entriesCompletedAction,
  invitationNotAccessibleAction,
  setStepAction,
  managersSelectedAction
} from '../../redux/actions/assessment';

/*
    Hook which provides services to present the energy ratings questions
*/
const useAssessment = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const functions = getFunctions();

  const [invitation, setInvitation] = useState();
  const [loaded, setLoaded] = useState(false);
  const [entry, setEntry] = useState();
  const {
    invitations,
    selectedInvitationIndex,
    invitationNotAccessible,
    notAccessibleReason,
    step,
    entryIndex,
    journey,
    completed
  } = useSelector(state => state.Assessment);

  const {
    brandedPortalParticipantValidation,
    brandedPortalExplainerVideoUrl,
    brandedPortalPath,
    organisationId,
    brandedPortalInstructions,
    brandedPortalInstructionsOnFailure
  } = useSelector(state => state.Branding);

  async function loadInvitations({
    invitationId,
    employeeCode,
    email,
    lastName
  }) {
    let payload = {
      path: brandedPortalPath,
      organisationId,
      email,
      lastName,
      employeeCode,
      invitationId
    };

    if (payload.employeeCode || payload.invitationId) {
      await getInvitations(payload);
    }
  }

  useEffect(() => {
    if (params?.invitationId || params?.employeeCode) {
      loadInvitations({ params });
    }
  }, [params]);

  useEffect(() => {
    if (!invitations || invitations.length === 0 || !invitation) {
      setEntry(undefined);
    } else if (Array.isArray(invitation?.submission?.entries)) {
      setEntry(
        invitation.submission.entries.filter(e => !e.notRequired)[entryIndex]
      );
    }
  }, [entryIndex, invitation]);

  useEffect(() => {
    if (
      selectedInvitationIndex === -1 ||
      !invitations ||
      invitations?.length === 0
    ) {
      setInvitation(undefined);
    } else {
      setInvitation(invitations[selectedInvitationIndex]);
    }
    setLoaded(true);
  }, [selectedInvitationIndex, invitations]);

  useEffect(() => {
    async function saveEntries() {
      await finaliseEntries(invitation);
    }
    if (completed && invitation) {
      saveEntries();
    }
  }, [completed]);

  if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }

  const getInvitations = async data => {
    const plgInvitationsFunction = httpsCallable(
      functions,
      'csg_get_my_invitations'
    );
    const result = await plgInvitationsFunction(data);
    dispatch(invitationsLoadedAction(result.data));
  };

  const storeMetrics = async data => {
    const plgMetricsFunction = httpsCallable(
      functions,
      'csg_store_participant_metrics'
    );
    await plgMetricsFunction(data);
  };

  const finaliseEntries = async () => {
    try {
      const response = await fetch(
        'http://127.0.0.1:5001/csg-pim/us-central1/csg_submit_participant_entries',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(invitations[selectedInvitationIndex])
        }
      );

      const result = await response.json();
      dispatch(submissionEntriesFinalisedAction(result));
    } catch (error) {
      console.error('Error finalizing entries:', error);
    }
  };

  const saveDataPointValue = async (dataPointId, value) => {
    const plgDataPointFunction = httpsCallable(
      functions,
      'csg_save_insight_datapoint'
    );
    await plgDataPointFunction({
      organisationId: invitation.organisationId,
      assessmentId: invitation.assessmentId,
      submissionId: invitation.submissionId,
      insightConfigurationId: invitation.configuration.insights.id,
      dataPointId,
      value
    });
  };

  const selectInvitation = async invitation => {
    dispatch(invitationsSelectedAction(invitation));
  };

  const selectColleagues = async entries => {
    dispatch(colleaguesSelectedAction(entries));
  };

  const selectManagers = async entries => {
    dispatch(managersSelectedAction(entries));
  };

  const nextStep = async data => {
    if (
      step === ASSESSMENT_STEPS.EMPLOYEE_CODE_AND_EMAIL ||
      step === ASSESSMENT_STEPS.EMPLOYEE_CODE_AND_LAST_NAME
    ) {
      await loadInvitations(data);
    } else if (
      step === ASSESSMENT_STEPS.INTRODUCTION &&
      brandedPortalParticipantValidation ===
        PARTICIPANT_VALIDATION_TYPE.INVITATION_ID
    ) {
      await selectInvitation(invitations[0].id);
    } else if (
      step === ASSESSMENT_STEPS.EMAIL ||
      step === ASSESSMENT_STEPS.NAME
    ) {
      await getInvitations({
        ...data,
        organisationId,
        path: brandedPortalPath
      });
    } else if (step === ASSESSMENT_STEPS.SELECT_INVITATION) {
      if (data?.configuration?.assessment?.inaccessible) {
        dispatch(
          invitationNotAccessibleAction(data?.configuration?.assessment?.reason)
        );
        return;
      } else {
        await selectInvitation(data);
      }
    } else if (step === ASSESSMENT_STEPS.RATE_ENERGIES) {
      dispatch(entriesCompletedAction());
    } else if (step === ASSESSMENT_STEPS.CHOOSE_COLLEAGUES) {
      await selectColleagues(data);
    } else if (step === ASSESSMENT_STEPS.CHOOSE_MANAGER) {
      await selectManagers(data);
    }
    dispatch(nextStepAction(data));
  };

  const previousStep = async () => {
    dispatch(previousStepAction());
  };

  const setStep = async step => {
    dispatch(setStepAction(step));
  };

  return {
    getInvitations,
    selectInvitation,
    finaliseEntries,
    storeMetrics,
    nextStep,
    previousStep,
    setStep,
    step,
    journey,
    invitation,
    loaded,
    entry,
    invitations,
    preInstructions: brandedPortalInstructions,
    failureInstructions: brandedPortalInstructionsOnFailure,
    explainerVideoUrl: brandedPortalExplainerVideoUrl,
    configuration: invitation?.configuration?.assessment,
    participationType: brandedPortalParticipantValidation,
    PARTICIPANT_VALIDATION_TYPE,
    notAccessibleReason,
    invitationNotAccessible,
    saveDataPointValue
  };
};

export default useAssessment;
