import React, { useEffect, useState } from 'react';
import useAssessment from '../../../../hooks/assessment';
import Button from '../../../Button';
import clsx from 'clsx';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const ChooseManager = () => {
  const { nextStep, invitation } = useAssessment();

  const [error, setError] = useState();
  const [selections, setSelections] = useState([]);
  const [managers, setManagers] = useState();

  useEffect(() => {
    async function load() {
      if (!invitation.configuration.assessment.pimChooseManager) {
        await nextStep(selections); // prevents this step from loading
      } else {
        setManagers(invitation.managers);
      }
    }
    if (invitation) {
      load();
    }
  }, [invitation]);

  const search = value => {
    if (!value || value.toString().trim().length === 0) {
      setManagers([...invitation.managers]);
    } else {
      setManagers(
        [...invitation.managers].filter(
          e =>
            `${e.firstName} ${e.lastName}`
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1 ||
            e.jobTitle.toLowerCase().indexOf(value.toLowerCase()) > -1
        )
      );
    }
  };

  if (!managers) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center p-8 mt-8 text-md items-center text-center w-full">
      <p className="mb-4">
        {invitation.configuration.assessment.pimChooseManagerText}
      </p>
      <div className="mb-8">
        <input
          onChange={e => search(e.target.value)}
          type="text"
          name="search"
          placeholder="Search by name or job title"
          style={{ width: 300 }}
        />
      </div>
      <div className="flex flex-wrap">
        {managers?.map((manager, index) => (
          <div
            key={index}
            onClick={() => {
              if (selections?.some(s => s.id === manager.id)) {
                setSelections([...selections.filter(s => s.id !== manager.id)]);
              } else {
                setSelections([...selections, manager]);
              }
            }}
            className={clsx(
              'm-1',
              selections?.some(s => s.id === manager.id)
                ? 'border-button border-2'
                : 'border-plg-navy-100 border-1',
              'border-1 rounded-lg border inline-block cursor-pointer py-4 align-middle mb-1  bg-white flex flex-col text-center items-center'
            )}>
            <div style={{ fontSize: 18, paddingLeft: 10, paddingRight: 10 }}>
              {manager.firstName} {manager.lastName}
              <p className="text-sm text-gray-500">{manager.jobTitle}</p>
            </div>
            {selections?.some(s => s.id === manager.id) ? (
              <CheckCircleIcon width={24} height={24} color="green" />
            ) : null}
          </div>
        ))}
      </div>

      <div className="mt-2 text-red" style={{ fontSize: 18, color: 'red' }}>
        {error}
      </div>
      <div className="flex w-full justify-center">
        {selections?.length > 0 ? (
          <Button
            onClick={() => {
              if (selections.length > 0) {
                nextStep(selections);
              } else {
                setError('At least one manager must be chosen.');
              }
            }}
            text="Continue"
          />
        ) : null}
      </div>
    </div>
  );
};

export default ChooseManager;
