import React from 'react';
import Routing from '../routing';
import useBranding from '../hooks/branding';
import LoadingSpinner from '../utils/LoadingSpinner';
import InvalidPathRedirect from '../views/InvalidPathRedirect';

const Scaffolding = () => {
  const { branding } = useBranding();

  if (branding.error) {
    return <InvalidPathRedirect />;
  }

  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: branding?.brandedPortalBgColor
          ? branding.brandedPortalBgColor
          : 'transparent'
      }}>
      {branding?.loaded ? (
        <Routing branding={branding} />
      ) : (
        <div className="flex justify-center items-center">
          <LoadingSpinner showLogo={false} />
        </div>
      )}
    </div>
  );
};

export default Scaffolding;
