import React, { useState, useEffect } from 'react';
import useAssessment from '../../../../hooks/assessment';
import RatingBlocks from './RatingBlocks';
import { useDispatch, useSelector } from 'react-redux';
import { individualEntrySubmittedAction } from '../../../../redux/actions/assessment';
import { isMobileOnly } from 'react-device-detect';
import LoadingSpinner from '../../../../utils/LoadingSpinner';

const RateEnergies = () => {
  const { configuration, invitation, entry, nextStep } = useAssessment();

  const dispatch = useDispatch();
  const { brandedPortalBgColor } = useSelector(state => state.Branding);
  const [colors, setColors] = useState();
  const [tooltips, setToolTips] = useState();
  const [indicators, setIndicators] = useState();
  const [items, setItems] = useState();
  const [vertical, setVertical] = useState(true);
  const [showMobileSpinner, setShowMobileSpinner] = useState(false);

  useEffect(() => {
    if (configuration) {
      if (configuration.pimDisplayVertically || isMobileOnly) {
        setItems([6, 5, 4, 3, 2, 1, 0]);
      } else {
        setItems([6, 5, 4, 3, 2, 1, 0].reverse());
      }
      setVertical(configuration.pimDisplayVertically || isMobileOnly);
      setColors(
        [1, 2, 3, 4, 5, 6, 7].map(index => configuration[`pimColor${index}`])
      );
      setToolTips(
        [1, 2, 3, 4, 5, 6, 7].map(
          index => configuration[`pimRatingTooltip${index}`]
        )
      );
      setIndicators(
        [1, 2, 3, 4, 5, 6, 7].map(
          index => configuration[`pimRating${index}Text`]
        )
      );
    }
  }, [configuration]);

  if (!configuration || !invitation) {
    return null;
  }

  const onRated = async (entry, rating) => {
    const payload = {
      id: entry.id,
      value: rating,
      advanceEntry: !configuration.pimMultipleRatings || isMobileOnly
    };
    dispatch(individualEntrySubmittedAction(payload));

    const entries = invitation.submission.entries.filter(e => !e.notRequired);
    const submitted = entries.filter(e => e.value > 0);

    if (!payload.advanceEntry) {
      if (submitted.length === entries.length) {
        nextStep();
      }
    } else {
      // show a spinner on mobile before we show the next rating block
      if (submitted.length !== entries.length) {
        setShowMobileSpinner(true);
        setTimeout(() => {
          setShowMobileSpinner(false);
          window.scrollTo(0, 0);
        }, 750);
      }
    }
  };

  if (showMobileSpinner) {
    return (
      <div>
        <LoadingSpinner
          showLogo={false}
          footertext={`Saving ${entry.firstName} ${entry.lastName}'s relationship rating...`}
        />
      </div>
    );
  }

  if (configuration.pimMultipleRatings && !isMobileOnly) {
    return (
      <div
        className="mt-2 mx-4"
        style={{ backgroundColor: brandedPortalBgColor }}>
        {invitation.submission.entries
          .filter(e => !e.notRequired)
          .map((entry, index) => (
            <RatingBlocks
              key={index}
              entry={entry}
              colors={colors}
              tooltips={tooltips}
              indicators={indicators}
              items={items}
              vertical={vertical}
              onRated={onRated}
            />
          ))}
      </div>
    );
  } else {
    return (
      <div className="mt-2" style={{ backgroundColor: brandedPortalBgColor }}>
        <RatingBlocks
          entry={entry}
          colors={colors}
          tooltips={tooltips}
          indicators={indicators}
          items={items}
          vertical={vertical}
          onRated={onRated}
        />
      </div>
    );
  }
};

export default RateEnergies;
