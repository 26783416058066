import React, { useEffect, useState } from 'react';
import useAssessment from '../../../../hooks/assessment';
import Button from '../../../Button';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const ChooseColleagues = () => {
  const { nextStep, invitation } = useAssessment();

  const { brandedPortalChooseColleagueCount } = useSelector(
    state => state.Branding
  );
  const [error, setError] = useState();
  const [selections, setSelections] = useState([]);
  const [colleagues, setColleagues] = useState();

  useEffect(() => {
    async function load() {
      if (
        !invitation.configuration.assessment.pimChooseColleagues &&
        invitation.colleagues < brandedPortalChooseColleagueCount
      ) {
        await nextStep([selections]); // prevents this step from loading
      }
    }
    if (invitation) {
      load();
    }
  }, [invitation]);

  useEffect(() => {
    if (invitation) {
      const displayColleagues = [...invitation.colleagues];
      displayColleagues.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
      setColleagues(displayColleagues);
    }
  }, [invitation]);

  const search = value => {
    if (!value || value.toString().trim().length === 0) {
      setColleagues([...invitation.colleagues]);
    } else {
      setColleagues(
        [...invitation.colleagues].filter(
          e =>
            `${e.firstName} ${e.lastName}`
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1 ||
            e.jobTitle.toLowerCase().indexOf(value.toLowerCase()) > -1
        )
      );
    }
  };

  if (!colleagues) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center p-8 mt-8 text-md items-center text-center w-full">
      <p className="mb-4">
        {invitation.configuration.assessment.pimChooseColleaguesText}
      </p>
      <div className="mb-8">
        <input
          onChange={e => search(e.target.value)}
          type="text"
          name="search"
          placeholder="Search by name or job title"
          className="w-full"
        />
      </div>
      <div className="flex flex-col md:flex-row flex-wrap">
        {colleagues?.map((colleague, index) => (
          <div
            key={index}
            onClick={() => {
              if (selections?.some(s => s.id === colleague.id)) {
                setSelections([
                  ...selections.filter(s => s.id !== colleague.id)
                ]);
              } else {
                setSelections([...selections, colleague]);
              }
            }}
            className={clsx(
              'm-1 sm:flex sm:w-full md:w-auto',
              selections?.some(s => s.id === colleague.id)
                ? 'border-button border-2'
                : 'border-plg-navy-100 border-1',
              'border-1 rounded-lg border inline-block cursor-pointer py-4 align-middle mb-1  bg-white flex flex-col text-center items-center'
            )}>
            <div style={{ fontSize: 18, paddingLeft: 10, paddingRight: 10 }}>
              {colleague.firstName} {colleague.lastName}
              <p className="text-sm text-gray-500">{colleague.jobTitle}</p>
            </div>
            {selections?.some(s => s.id === colleague.id) ? (
              <CheckCircleIcon width={24} height={24} color="green" />
            ) : null}
          </div>
        ))}
      </div>

      <div className="mt-2 text-red" style={{ fontSize: 18, color: 'red' }}>
        {error}
      </div>
      <div className="flex w-full justify-center">
        {selections?.length > 0 ? (
          <Button
            onClick={() => {
              if (selections.length > 0) {
                nextStep(selections);
              } else {
                setError('At least one colleague must be chosen.');
              }
            }}
            text="Continue"
          />
        ) : null}
      </div>
    </div>
  );
};

export default ChooseColleagues;
