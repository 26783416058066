import React from 'react';
import useAssessment from '../../../../hooks/assessment';

const InvitationNotAccessible = () => {
  const { notAccessibleReason } = useAssessment();

  return (
    <div className="flex flex-col p-8 mt-4 text-md items-center text-center w-full">
      <p className="mb-8 mt-2 text-xl">{notAccessibleReason}</p>
    </div>
  );
};

export default InvitationNotAccessible;
