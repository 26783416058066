import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../firebase';
import { onSnapshot, doc } from 'firebase/firestore';

import {
  brandingErrorAction,
  brandingLoadedAction
} from '../../redux/actions/branding';
import {
  JOURNEYS,
  PARTICIPANT_VALIDATION_TYPE
} from '../../components/Assessment/Constants';
import { assessmentJourneyLoadedAction } from '../../redux/actions/assessment';

const propertyMap = {
  brandedPortalHeaderBgColor: '--color-header',
  brandedPortalButtonBgColor: '--color-button',
  brandedPortalButtonBgHoverColor: '--color-button-hover',
  brandedPortalButtonFontColor: '--color-button-text',
  brandedPortalButtonTextHoverColor: '--color-button-text-hover',
  brandedPortalButtonFontSize: '--font-size-button',
  brandedPortalBgColor: '--color-body'
};

export const parseBrandUrl = () => {
  const url = window.location.pathname;
  /* eslint-disable-next-line */
  const employeeCodeRegex = /^\/([^\/]+)\/c\/([^\/]+)$/; // Pattern for /:path/c/:employeeCode ignoring querystrings
  /* eslint-disable-next-line */
  const invitationIdRegex = /^\/([^\/]+)\/i\/([^\/]+)$/; // Pattern for /:path/i/:invitationId ignoring querystrings

  let match = url.match(employeeCodeRegex);
  if (match) {
    const path = match[1];
    const employeeCode = match[2];
    return {
      path,
      employeeCode,
      invitationId: undefined
    };
  }

  match = url.match(invitationIdRegex);
  if (match) {
    const path = match[1];
    const invitationId = match[2];
    return {
      path,
      employeeCode: undefined,
      invitationId
    };
  }

  // path without employee code or invitation id
  if (window.location.pathname?.trim().length > 0) {
    return {
      path: window.location.pathname.replace('/', '')
    };
  }

  return;
};

const useBranding = () => {
  const dispatch = useDispatch();

  const branding = useSelector(state => state.Branding);

  const convertBrandedValue = (cssVariableName, value) => {
    if (value !== undefined && cssVariableName.indexOf('--color') > -1) {
      const [r, g, b] = value.match(/\w\w/g).map(x => parseInt(x, 16));
      return `${r} ${g} ${b}`;
    } else if (cssVariableName.indexOf('--font-size') > -1) {
      return `${value}px`;
    }
    return value;
  };

  useEffect(() => {
    if (branding?.loaded && Object.keys(branding).length > 1) {
      Object.keys(propertyMap).forEach(key => {
        document.documentElement.style.setProperty(
          propertyMap[key],
          convertBrandedValue(propertyMap[key], branding[key])
        );
      });
    }
  }, [branding]);

  const watchBrand = async () => {
    const params = parseBrandUrl();

    if (!params || params.path?.trim().length === 0) {
      dispatch(brandingErrorAction('invalid url'));
      return;
    }

    return onSnapshot(doc(db, 'branding', params.path), doc => {
      const brandSetup = doc.data();
      let journey;
      if (params.invitationId) {
        journey = JOURNEYS[PARTICIPANT_VALIDATION_TYPE.INVITATION_ID];
      } else if (params.employeeCode) {
        journey = JOURNEYS[PARTICIPANT_VALIDATION_TYPE.EMPLOYEE_CODE_IN_URL];
      } else {
        journey = JOURNEYS[brandSetup.brandedPortalParticipantValidation];
      }
      dispatch(assessmentJourneyLoadedAction(journey));
      dispatch(brandingLoadedAction(brandSetup));
    });
  };

  useEffect(() => {
    watchBrand();
  }, [window.location.href]);

  return {
    branding
  };
};

export default useBranding;
