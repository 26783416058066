import { ASSESSMENT_ACTIONS } from '../../actions/assessment';
import { ASSESSMENT_STEPS } from '../../../components/Assessment/Constants';

const initialState = {
  invitations: undefined,
  selectedInvitationIndex: -1,
  invitationNotAccessible: false,
  notAccessibleReason: '',
  step: undefined,
  entryIndex: 0,
  journey: undefined,
  completed: false
};

/* a data store for the current assessment (test) being executed */
const AssessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSESSMENT_ACTIONS.JOURNEY_LOADED: {
      return {
        ...state,
        journey: action.payload,
        step: action.payload[0]
      };
    }
    case ASSESSMENT_ACTIONS.SET_STEP: {
      const stepIndex = state.journey.findIndex(s => s === action.payload);
      if (stepIndex === -1) {
        return { ...state };
      }
      return {
        ...state,
        step: state.journey[stepIndex]
      };
    }
    case ASSESSMENT_ACTIONS.NEXT_STEP: {
      const stepIndex = state.journey.findIndex(s => s === state.step);
      if (stepIndex === state.journey.length - 1) {
        return { ...state };
      }
      return {
        ...state,
        step: state.journey[stepIndex + 1]
      };
    }
    case ASSESSMENT_ACTIONS.PREVIOUS_STEP: {
      const stepIndex = state.journey.findIndex(s => s === state.step);
      if (stepIndex === 0) {
        return { ...state };
      }
      return {
        ...state,
        step: state.journey[stepIndex - 1]
      };
    }
    case ASSESSMENT_ACTIONS.INVITATIONS_LOADED: {
      return {
        ...state,
        invitationNotAccessible: false,
        notAccessibleReason: '',
        //selectedInvitationIndex: action.payload?.length === 1 ? 0 : -1,
        invitations: action.payload
      };
    }
    case ASSESSMENT_ACTIONS.INVITATION_SELECTED: {
      const index =
        state.invitations?.length > 0
          ? state.invitations.findIndex(i => i.id === action.payload.id)
          : -1;
      return {
        ...state,
        invitationNotAccessible: false,
        notAccessibleReason: '',
        selectedInvitationIndex: index
      };
    }
    case ASSESSMENT_ACTIONS.INVITATION_NOT_ACCESSIBLE: {
      return {
        ...state,
        step: ASSESSMENT_STEPS.INVITATION_NOT_ACCESSIBLE,
        invitationNotAccessible: true,
        notAccessibleReason: action.payload
      };
    }
    case ASSESSMENT_ACTIONS.COLLEAGUES_SELECTED: {
      const newState = { ...state };
      newState.invitations[
        newState.selectedInvitationIndex
      ].submission.entries = [
        ...newState.invitations[newState.selectedInvitationIndex].submission
          .entries,
        ...action.payload
      ];

      return newState;
    }
    case ASSESSMENT_ACTIONS.MANAGERS_SELECTED: {
      const newState = { ...state };
      newState.invitations[
        newState.selectedInvitationIndex
      ].submission.entries = [...action.payload];

      return newState;
    }
    case ASSESSMENT_ACTIONS.ASSESSMENT_JOURNEY_ENDED: {
      return {
        ...state,
        invitations: undefined,
        entryIndex: 0,
        selectedInvitationIndex: -1,
        invitationNotAccessible: false,
        notAccessibleReason: ''
      };
    }
    case ASSESSMENT_ACTIONS.SUBMISSION_ENTRIES_COMPLETED: {
      return {
        ...state,
        completed: true
      };
    }
    case ASSESSMENT_ACTIONS.SUBMISSION_ENTRIES_FINALISED: {
      const newState = { ...state };
      if (
        state.selectedInvitationIndex > -1 &&
        state.selectedInvitationIndex < state.invitations.length - 1
      ) {
        newState.invitations[state.selectedInvitationIndex].completed = true;
      }

      return newState;
    }
    case ASSESSMENT_ACTIONS.INDIVIDUAL_ENTRY_SUBMITTED: {
      const newState = { ...state };

      const entryIndex = newState.invitations[
        state.selectedInvitationIndex
      ].submission.entries.findIndex(e => e.id === action.payload.id);

      newState.invitations[state.selectedInvitationIndex].submission.entries[
        entryIndex
      ].value = action.payload.value;

      if (action.payload.advanceEntry) {
        newState.entryIndex++;

        if (
          newState.entryIndex >=
          newState.invitations[
            newState.selectedInvitationIndex
          ].submission.entries.filter(e => !e.notRequired).length
        ) {
          const stepIndex = state.journey.findIndex(s => s === state.step);
          newState.step = state.journey[stepIndex + 1];
          newState.completed = true;
        }
      }

      return newState;
    }
    default:
      return state;
  }
};

export default AssessmentReducer;
