const BRANDING_ACTIONS = {
  BRANDING_LOADED: 'BRANDING_LOADED',
  BRANDING_ERROR: 'BRANDING_ERROR'
};

const brandingLoadedAction = branding => {
  return {
    type: BRANDING_ACTIONS.BRANDING_LOADED,
    payload: branding
  };
};

const brandingErrorAction = reason => {
  return {
    type: BRANDING_ACTIONS.BRANDING_ERROR,
    payload: reason
  };
};

export { BRANDING_ACTIONS, brandingLoadedAction, brandingErrorAction };
