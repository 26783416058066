import { initializeApp } from 'firebase/app';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider
} from 'firebase/app-check';
import { getFirestore, serverTimestamp } from '@firebase/firestore';
import { getAuth } from '@firebase/auth';
import firebaseConfig from './config.json';
import appCheckConfig from './appcheck.json';

const APP_TYPES = {
  ACTIVECOURSE: 'ACTIVECOURSE',
  BEAUTTY: 'BEAUTY',
  BOOKINGS: 'BOOKINGS',
  RECRUITMENT: 'RECRUITMENT',
  EMPTY: 'EMPTY',
  QUOTING: 'QUOTING',
  ALL: 'ALL',
  CUSTOM: 'CUSTOM'
};

const APP_TYPE = APP_TYPES.EMPTY;

// localhost only: uncomment below and add debug token from console into AppCheck
/* eslint-disable no-restricted-globals */
if (process.env.REACT_APP_LOCAL_APPCHECK_DEBUG_TOKEN) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const app = initializeApp(firebaseConfig);

if (appCheckConfig.key != null) {
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(appCheckConfig.key),
    isTokenAutoRefreshEnabled: true
  });
}

const db = getFirestore(app);

const auth = getAuth(app);

export { app, db, auth, serverTimestamp, APP_TYPE, APP_TYPES, firebaseConfig };
