import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ConfigurationError from '../views/ConfigurationError';
import Dashboard from '../views/Dashboard';
import InvalidPathRedirect from '../views/InvalidPathRedirect';

const Routing = () => {
  return (
    <Routes>
      <Route path="/:path" element={<Dashboard />} />
      <Route path="/:path/i/:invitationId" element={<Dashboard />} />
      <Route path="/:path/c/:employeeCode" element={<Dashboard />} />
      <Route path="/error" element={<ConfigurationError />} />
      <Route path="*" element={<InvalidPathRedirect />} />
    </Routes>
  );
};
export default Routing;
