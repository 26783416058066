const PARTICIPANT_VALIDATION_TYPE = {
  EMPLOYEE_CODE_AND_EMAIL: 'code',
  EMPLOYEE_CODE_AND_LAST_NAME: 'code_lastname',
  EMPLOYEE_CODE_IN_URL: 'code_url',
  INVITATION_ID: 'invitation_link'
};

const ASSESSMENT_STEPS = {
  INTRODUCTION: 'INTRODUCTION',
  PRE_INSIGHTS: 'PRE_INSIGHTS',
  EMAIL: 'EMAIL',
  NAME: 'NAME',
  CHOOSE_COLLEAGUES: 'CHOOSE_COLLEAGUES',
  CHOOSE_MANAGER: 'CHOOSE_MANAGER',
  SELECT_INVITATION: 'SELECT_INVITATION',
  RATE_ENERGIES: 'RATE_ENERGIES',
  COMPLETION_INSIGHTS: 'COMPLETION_INSIGHTS',
  RESULT: 'RESULT',
  INVITATION_NOT_ACCESSIBLE: 'INVITATION_NOT_ACCESSIBLE',
  EMPLOYEE_CODE_AND_EMAIL: 'EMPLOYEE_CODE_AND_EMAIL',
  EMPLOYEE_CODE_AND_LAST_NAME: 'EMPLOYEE_CODE_AND_LAST_NAME'
};

const IDENTIFY_BY_INVITATION_ID_STEPS = [
  ASSESSMENT_STEPS.INTRODUCTION,
  ASSESSMENT_STEPS.PRE_INSIGHTS,
  ASSESSMENT_STEPS.CHOOSE_MANAGER,
  ASSESSMENT_STEPS.CHOOSE_COLLEAGUES,
  ASSESSMENT_STEPS.RATE_ENERGIES,
  ASSESSMENT_STEPS.COMPLETION_INSIGHTS,
  ASSESSMENT_STEPS.RESULT
];

const IDENTIFY_BY_EMPLOYEE_CODE_IN_URL_STEPS = [
  ASSESSMENT_STEPS.SELECT_INVITATION,
  ASSESSMENT_STEPS.INTRODUCTION,
  ASSESSMENT_STEPS.PRE_INSIGHTS,
  ASSESSMENT_STEPS.CHOOSE_MANAGER,
  ASSESSMENT_STEPS.CHOOSE_COLLEAGUES,
  ASSESSMENT_STEPS.RATE_ENERGIES,
  ASSESSMENT_STEPS.COMPLETION_INSIGHTS,
  ASSESSMENT_STEPS.RESULT
];

const IDENTIFY_BY_EMPLOYEE_CODE_AND_EMAIL_ENTERED_STEPS = [
  ASSESSMENT_STEPS.EMPLOYEE_CODE_AND_EMAIL,
  ASSESSMENT_STEPS.SELECT_INVITATION,
  ASSESSMENT_STEPS.INTRODUCTION,
  ASSESSMENT_STEPS.PRE_INSIGHTS,
  ASSESSMENT_STEPS.CHOOSE_MANAGER,
  ASSESSMENT_STEPS.CHOOSE_COLLEAGUES,
  ASSESSMENT_STEPS.RATE_ENERGIES,
  ASSESSMENT_STEPS.COMPLETION_INSIGHTS,
  ASSESSMENT_STEPS.RESULT
];

const IDENTIFY_BY_EMPLOYEE_CODE_AND_LAST_NAME_ENTERED_STEPS = [
  ASSESSMENT_STEPS.EMPLOYEE_CODE_AND_LAST_NAME,
  ASSESSMENT_STEPS.SELECT_INVITATION,
  ASSESSMENT_STEPS.INTRODUCTION,
  ASSESSMENT_STEPS.PRE_INSIGHTS,
  ASSESSMENT_STEPS.CHOOSE_MANAGER,
  ASSESSMENT_STEPS.CHOOSE_COLLEAGUES,
  ASSESSMENT_STEPS.RATE_ENERGIES,
  ASSESSMENT_STEPS.COMPLETION_INSIGHTS,
  ASSESSMENT_STEPS.RESULT
];

const JOURNEYS = {};

JOURNEYS[
  PARTICIPANT_VALIDATION_TYPE.INVITATION_ID
] = IDENTIFY_BY_INVITATION_ID_STEPS;

JOURNEYS[
  PARTICIPANT_VALIDATION_TYPE.EMPLOYEE_CODE_AND_EMAIL
] = IDENTIFY_BY_EMPLOYEE_CODE_AND_EMAIL_ENTERED_STEPS;

JOURNEYS[
  PARTICIPANT_VALIDATION_TYPE.EMPLOYEE_CODE_AND_LAST_NAME
] = IDENTIFY_BY_EMPLOYEE_CODE_AND_LAST_NAME_ENTERED_STEPS;

JOURNEYS[
  PARTICIPANT_VALIDATION_TYPE.EMPLOYEE_CODE_IN_URL
] = IDENTIFY_BY_EMPLOYEE_CODE_IN_URL_STEPS;

export { ASSESSMENT_STEPS, JOURNEYS, PARTICIPANT_VALIDATION_TYPE };
