const ASSESSMENT_ACTIONS = {
  INVITATIONS_LOADED: 'INVITATIONS_LOADED',
  INVITATION_SELECTED: 'INVITATION_SELECTED',
  INVITATION_NOT_ACCESSIBLE: 'INVITATION_NOT_ACCESSIBLE',
  COLLEAGUES_SELECTED: 'COLLEAGUES_SELECTED',
  MANAGERS_SELECTED: 'MANAGERS_SELECTED',
  INDIVIDUAL_ENTRY_SUBMITTED: 'INDIVIDUAL_ENTRY_SUBMITTED',
  SUBMISSION_ENTRIES_FINALISED: 'SUBMISSION_ENTRIES_FINALISED',
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  SET_STEP: 'SET_STEP',
  JOURNEY_LOADED: 'JOURNEY_LOADED',
  ASSESSMENT_JOURNEY_ENDED: 'ASSESSMENT_JOURNEY_ENDED',
  SUBMISSION_ENTRIES_COMPLETED: 'SUBMISSION_ENTRIES_COMPLETED'
};

const assessmentJourneyEndedAction = () => {
  return {
    type: ASSESSMENT_ACTIONS.ASSESSMENT_JOURNEY_ENDED,
    payload: {}
  };
};

const assessmentJourneyLoadedAction = journey => {
  return {
    type: ASSESSMENT_ACTIONS.JOURNEY_LOADED,
    payload: journey
  };
};

const invitationsLoadedAction = invitations => {
  return {
    type: ASSESSMENT_ACTIONS.INVITATIONS_LOADED,
    payload: invitations
  };
};

const invitationsSelectedAction = invitation => {
  return {
    type: ASSESSMENT_ACTIONS.INVITATION_SELECTED,
    payload: invitation
  };
};

const invitationNotAccessibleAction = reason => {
  return {
    type: ASSESSMENT_ACTIONS.INVITATION_NOT_ACCESSIBLE,
    payload: reason
  };
};

const colleaguesSelectedAction = entries => {
  return {
    type: ASSESSMENT_ACTIONS.COLLEAGUES_SELECTED,
    payload: entries
  };
};

const managersSelectedAction = entries => {
  return {
    type: ASSESSMENT_ACTIONS.MANAGERS_SELECTED,
    payload: entries
  };
};

const individualEntrySubmittedAction = ({ id, value, advanceEntry }) => {
  return {
    type: ASSESSMENT_ACTIONS.INDIVIDUAL_ENTRY_SUBMITTED,
    payload: { id, value, advanceEntry }
  };
};

const submissionEntriesFinalisedAction = () => {
  return {
    type: ASSESSMENT_ACTIONS.SUBMISSION_ENTRIES_FINALISED,
    payload: {}
  };
};

const entriesCompletedAction = () => {
  return {
    type: ASSESSMENT_ACTIONS.SUBMISSION_ENTRIES_COMPLETED,
    payload: {}
  };
};

const nextStepAction = () => {
  return {
    type: ASSESSMENT_ACTIONS.NEXT_STEP,
    payload: {}
  };
};

const previousStepAction = () => {
  return {
    type: ASSESSMENT_ACTIONS.PREVIOUS_STEP,
    payload: {}
  };
};

const setStepAction = step => {
  return {
    type: ASSESSMENT_ACTIONS.SET_STEP,
    payload: step
  };
};

export {
  ASSESSMENT_ACTIONS,
  invitationsLoadedAction,
  individualEntrySubmittedAction,
  invitationsSelectedAction,
  managersSelectedAction,
  colleaguesSelectedAction,
  submissionEntriesFinalisedAction,
  nextStepAction,
  previousStepAction,
  assessmentJourneyLoadedAction,
  assessmentJourneyEndedAction,
  entriesCompletedAction,
  invitationNotAccessibleAction,
  setStepAction
};
