import React, { useEffect, useState } from 'react';
import useAssessment from '../../../../../hooks/assessment';

import { isMobileOnly } from 'react-device-detect';
import clsx from 'clsx';
import {
  ArrowLongLeftIcon,
  ArrowUpIcon,
  CheckCircleIcon
} from '@heroicons/react/24/solid';

const RatingBlocks = props => {
  const {
    entry,
    colors,
    indicators,
    tooltips,
    vertical,
    items,
    onRated
  } = props;

  const { invitation, configuration } = useAssessment();

  const [indexSelected, setSelectedIndex] = useState();

  useEffect(() => {
    setSelectedIndex(undefined);
  }, [entry]);

  if (!entry || !invitation || !configuration) {
    return null;
  }

  const selectRating = async index => {
    setSelectedIndex(index);
    setTimeout(() => onRated(entry, index + 1), 700);
  };

  return (
    <div className="flex flex-col px-8 py-2 text-md text-center justify-center items-center">
      <div className="font-bold text-sm">
        {configuration.pimQuestionText.indexOf('{') > -1 &&
        !configuration.pimQuestionStandoutName ? (
          <>
            {`${configuration.pimQuestionText.replace(
              '{name}',
              `${entry.firstName} ${entry.lastName}`
            )}`}
          </>
        ) : (
          <>
            {configuration.pimQuestionText} {entry.firstName} {entry.lastName}
          </>
        )}
      </div>
      {configuration.pimQuestionStandoutName ? (
        <h1 className="text-2xl text-bold mx-auto">
          {entry.firstName} {entry.lastName}
        </h1>
      ) : null}
      <div className={clsx('flex mt-4', vertical ? 'flex-col md:ml-48' : '')}>
        {items.map(index => {
          const indicator =
            indicators[index] !== undefined &&
            indicators[index].trim().length > 0
              ? indicators[index]
              : undefined;

          const tooltip =
            tooltips[index] !== undefined && tooltips[index].trim().length > 0
              ? tooltips[index]
              : '';

          const color = colors[index];
          return (
            <div className="flex flex-row" key={index}>
              {configuration.pimDisplayVertically || isMobileOnly ? (
                <div
                  style={{ marginRight: 20 }}
                  className="flex justify-center w-6 text-bold items-center">
                  {index + 1}
                </div>
              ) : null}
              <RatingBlock
                index={index}
                vertical={vertical}
                selected={index === indexSelected}
                onClick={() => selectRating(index)}
                color={color}
                indicator={indicator}
                tooltip={tooltip}
              />
              {vertical && indicator ? (
                <div className="flex justify-center items-center ml-4">
                  <ArrowLongLeftIcon width={24} height={24} />
                  <div>{indicator}</div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const RatingBlock = ({
  index,
  onClick,
  selected,
  vertical,
  color,
  indicator,
  tooltip
}) => {
  const adjustIndex = vertical ? 0 : 6;

  return (
    <div className="flex flex-col">
      <div
        style={{ backgroundColor: color }}
        title={tooltip}
        className={clsx(
          'flex items-center justify-center border-black',
          adjustIndex === index
            ? vertical
              ? 'border-b-4 hover:border-b-4'
              : 'border-r-4 hover:border-r-4'
            : vertical
            ? 'hover:border-b-4'
            : 'hover:border-r-4',
          vertical
            ? 'border-t-4 border-l-4 border-r-4'
            : 'border-t-4 border-l-4 border-b-4',
          'p-4 cursor-pointer transition ease-in-out delay-50 duration-200 hover:-translate-1 hover:scale-110',
          'w-28 h-20'
        )}
        onClick={onClick}>
        {selected ? <CheckCircleIcon width={48} height={48} /> : null}
      </div>
      {!vertical ? <div className="mt-2">{index + 1}</div> : null}
      {!vertical && indicator ? (
        <div className="flex justify-center flex-col items-center mt-4">
          <ArrowUpIcon width={24} height={24} />
          <div className="mt-3 text-sm">{indicator}</div>
        </div>
      ) : null}
    </div>
  );
};

export default RatingBlocks;
