import { BRANDING_ACTIONS } from '../../actions/branding';

const initialState = { loaded: false, error: false, reason: '' };

const BrandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRANDING_ACTIONS.BRANDING_LOADED: {
      return {
        ...state,
        ...action.payload,
        error: false,
        reason: '',
        loaded: true,
        customised: Object.keys(action.payload).length > 0
      };
    }

    case BRANDING_ACTIONS.BRANDING_ERROR: {
      return {
        ...initialState,
        error: true,
        reason: action.payload
      };
    }

    default:
      return state;
  }
};

export default BrandingReducer;
