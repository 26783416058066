import React, { useEffect, useState } from 'react';
import useAssessment from '../../hooks/assessment';
import {
  EmployeeCode,
  Email,
  RateEnergies,
  Introduction,
  Result,
  FirstNameLastName,
  ChooseColleagues,
  PreInsights,
  CompletionInsights,
  InvitationNotAccessible,
  ChooseManager
} from './Steps';
import { ASSESSMENT_STEPS } from './Constants';
import SelectInvitation from './Steps/SelectInvitation';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../utils/LoadingSpinner';

const Assessment = () => {
  const { step, invitations, configuration, journey } = useAssessment();
  const branding = useSelector(state => state.Branding);

  const [thankYouPageTitle, setThankYouPageTitle] = useState('Thank You');
  const [thankYouPageParagrapah, setThankYouPageParagraph] = useState('');

  useEffect(() => {
    if (step !== ASSESSMENT_STEPS.RESULT && configuration) {
      setThankYouPageTitle(configuration.pimCompletedTitle);
      setThankYouPageParagraph(configuration.pimCompletedParagraph);
    }
  }, [step, configuration]);

  if (!branding) {
    return null;
  }

  const renderStep = step => {
    if (step === ASSESSMENT_STEPS.EMPLOYEE_CODE_AND_EMAIL) {
      return <EmployeeCode />;
    } else if (step === ASSESSMENT_STEPS.EMPLOYEE_CODE_AND_LAST_NAME) {
      return <EmployeeCode askForLastName />;
    }

    if (!invitations && step !== ASSESSMENT_STEPS.RESULT) {
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner showLogo={false} />
        </div>
      );
    }

    if (invitations?.length === 0) {
      if (
        journey[0] === ASSESSMENT_STEPS.EMPLOYEE_CODE_AND_EMAIL ||
        journey[0] === ASSESSMENT_STEPS.EMPLOYEE_CODE_AND_LAST_NAME
      ) {
        <div className="flex text-center justify-center mt-4">
          <p>
            The code you entered is not valid.
            <br /> Please try again.
          </p>
        </div>;
      } else {
        return (
          <div className="flex text-center justify-center mt-4">
            <p>
              Oops sorry. You seem to have an incorrect link.
              <br /> Please contact your organisation.
            </p>
          </div>
        );
      }
    }

    switch (step) {
      case ASSESSMENT_STEPS.INVITATION_NOT_ACCESSIBLE:
        return <InvitationNotAccessible />;
      case ASSESSMENT_STEPS.EMAIL:
        return <Email />;
      case ASSESSMENT_STEPS.NAME:
        return <FirstNameLastName />;
      case ASSESSMENT_STEPS.CHOOSE_COLLEAGUES:
        return <ChooseColleagues />;
      case ASSESSMENT_STEPS.CHOOSE_MANAGER:
        return <ChooseManager />;
      case ASSESSMENT_STEPS.SELECT_INVITATION:
        return <SelectInvitation />;
      case ASSESSMENT_STEPS.PRE_INSIGHTS:
        return <PreInsights />;
      case ASSESSMENT_STEPS.RATE_ENERGIES:
        return <RateEnergies />;
      case ASSESSMENT_STEPS.COMPLETION_INSIGHTS:
        return <CompletionInsights />;
      case ASSESSMENT_STEPS.RESULT:
        return (
          <Result title={thankYouPageTitle} text={thankYouPageParagrapah} />
        );
      default:
        return <Introduction />;
    }
  };

  return <div className="mx-2">{renderStep(step)}</div>;
};

export default Assessment;
